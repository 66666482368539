<template>
  <qtm-dialog-card
    closable
    :model-value="modelValue"
    persistent
    scrollable
    title="Items Matching"
    width="1178"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template v-slot:subtitle>
      <div class="px-2 mt-4">
        <qtm-table class="mb-8" :headers="itemHeaders" :items="[item]">
          <template v-slot:item.price="{ item: tableItem }">
            <div v-if="!tableItem.rental_duration">
              {{ currency(tableItem.unit_price) }}/{{ tableItem.unit }}
            </div>
            <div v-else>
              <div v-if="tableItem.day_rate">
                {{ currency(tableItem.day_rate) }}/day
              </div>
              <div v-if="tableItem.week_rate">
                {{ currency(tableItem.week_rate) }}/week
              </div>
              <div v-if="tableItem.month_rate">
                {{ currency(tableItem.month_rate) }}/month
              </div>
            </div>
          </template>
        </qtm-table>
        <div class="mb-2 mt-4" style="max-width: 309px">
          <div class="mb-2 text-dark-grey qtm-body">
            Find and select the matching {{ matchItemType }} item.
          </div>
          <qtm-text-field
            v-model="search"
            density="compact"
            hide-details
            placeholder="Search by description"
            prepend-inner-icon="mdi-magnify"
          />
        </div>

        <qtm-table :headers="headers">
          <template v-slot:empty>
            <div />
          </template>
        </qtm-table>
      </div>
    </template>
    <qtm-table :headers="headers" :items="filteredItems" no-header>
      <template v-slot:item.item_number="{ item: tableItem }">
        <v-checkbox
          v-if="item"
          v-model="items[activeIndex].reference_identifier"
          class="mt-n2"
          color="interactive"
          density="compact"
          :disabled="!canMatch(tableItem)"
          false-icon="mdi-circle-outline"
          :false-value="null"
          hide-details
          :label="String(tableItem.item_number ?? '')"
          true-icon="mdi-check-circle"
          :true-value="tableItem.internal_identifier"
          @update:model-value="setCostCode"
        />
      </template>
      <template v-slot:item.price="{ item: tableItem }">
        <div v-if="!tableItem.rental_duration">
          {{ currency(tableItem.unit_price) }}/{{ tableItem.unit }}
        </div>
        <div v-else>
          <div v-if="tableItem.day_rate">
            {{ currency(tableItem.day_rate) }}/day
          </div>
          <div v-if="tableItem.week_rate">
            {{ currency(tableItem.week_rate) }}/week
          </div>
          <div v-if="tableItem.month_rate">
            {{ currency(tableItem.month_rate) }}/month
          </div>
        </div>
      </template>
    </qtm-table>
    <template v-slot:actions>
      <qtm-btn
        :disabled="activeIndex === 0"
        class="pl-4 pr-6"
        prepend-icon="mdi-arrow-left"
        secondary
        size="x-large"
        @click="activeIndex -= 1"
      >
        Previous Item
      </qtm-btn>
      <qtm-btn
        append-icon="mdi-arrow-right"
        class="pl-6 pr-4"
        :disabled="activeIndex === items.length - 1"
        secondary
        size="x-large"
        @click="activeIndex += 1"
      >
        Next Item
      </qtm-btn>
      <v-spacer />
      <qtm-btn size="x-large" @click="saveAndClose">
        Save & Close
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import QtmTable from '@/components/qtm-table.vue'
import { currency } from '@/models/filters'

export interface Props {
  initialItem?: any
  itemsToMatch: any[]
  modelValue: boolean
  matchItems?: any[]
  matchItemType?: string
  qtyHeader?: string
  singleMatch?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  initialItem: undefined,
  matchItems: () => [],
  matchItemType: 'PO',
  qtyHeader: 'Qty',
})
const emit = defineEmits(['update:items-to-match', 'update:model-value'])

const activeIndex = ref(0)
const items = ref<any[]>([])
const item = computed(() => items.value[activeIndex.value])

watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    items.value = cloneDeep(props.itemsToMatch)
    activeIndex.value = items.value.findIndex(i => i.id === props.initialItem?.id) || 0
  }
})

const search = ref('')
const filteredItems = computed(() => {
  const cleanSearch = search.value?.toLowerCase().trim()

  if (cleanSearch) {
    return props.matchItems.filter((i) => i.description.toLowerCase().includes(cleanSearch))
  }

  return props.matchItems
})

const saveAndClose = () => {
  emit('update:items-to-match', items.value)
  emit('update:model-value', false)
}

const setCostCode = (identifier: string) => {
  const poItem = props.matchItems.find((i) => i.internal_identifier === identifier)

  if (poItem) {
    items.value[activeIndex.value].cost_code = poItem.cost_code
  }
}

const canMatch = (tableItem: any) => {
  return !props.singleMatch
    || items.value.filter(i => i.id !== item.value.id)
      .every(i => i.reference_identifier !== tableItem.internal_identifier)
}

const matchedToType = props.matchItemType === 'RFQ' ? 'Quote' : 'Invoice'

const QTY_WIDTH = 100
const PRICE_WIDTH = 150

const headers = [
  { title: '#', value: 'item_number', width: 75, cellClass: 'text-center' },
  { title: `${props.matchItemType} Item`, value: 'description' },
  { title: props.qtyHeader, value: 'quantity', width: QTY_WIDTH },
  { title: 'Unit Price', value: 'price', width: PRICE_WIDTH },
]

const itemHeaders = [
  { title: `${matchedToType} Item`, value: 'description' },
  { title: props.qtyHeader, value: 'quantity', width: QTY_WIDTH },
  { title: 'Unit Price', value: 'price', width: PRICE_WIDTH },
]
</script>
