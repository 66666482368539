<template>
  <v-tooltip location="top">
    <template v-slot:activator="{ props: activator }">
      <span v-if="copyOnClick" v-bind="activator" class="cursor-pointer" @click="copy" v-text="text" />
      <span v-else>
        <span v-if="!hidden" v-text="text" />
        <qtm-icon-btn v-bind="activator" class="mb-n2 mt-n1" color="mid-grey" icon="mdi-content-copy" @click="copy" />
      </span>
    </template>
    {{ copied ? 'Copied!' : 'Copy' }}
  </v-tooltip>
</template>

<script setup lang="ts">
export interface Props {
  copyOnClick?: boolean
  hidden?: boolean
  text: string
}

const props = defineProps<Props>()

const copied = ref(false)

let copiedTimeout: ReturnType<typeof setTimeout>

const copy = () => {
  navigator.clipboard.writeText(props.text)
  copied.value = true
  clearTimeout(copiedTimeout)
  copiedTimeout = setTimeout(() => {
    copied.value = false
  }, 3000)
}
</script>
