<template>
  <qtm-autocomplete
    v-bind="$attrs"
    data-test="jobsite-select"
    :disabled="disabled"
    hide-details="auto"
    :item-title="itemName"
    :items="items"
    :loading="loading"
    placeholder="Select Project"
    return-object
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template v-slot:append-item>
      <v-list-item class="qtm-label text-interactive" @click="showJoin = true">
        <v-icon class="mt-n1 mr-2" icon="mdi-plus" />
        Join
      </v-list-item>
    </template>
  </qtm-autocomplete>
  <join-jobsite-dialog v-model="showJoin" @joined="joined" />
</template>

<script setup lang="ts">
import type { Jobsite, User } from '@quotetome/materials-api'
import JoinJobsiteDialog from '@/components/jobsites/join-jobsite-dialog.vue'

export interface Props {
  disabled?: boolean
  params?: any
  user?: User
  userRequired?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  params: undefined,
  user: undefined,
  userRequired: true,
})
const emit = defineEmits(['update:model-value'])

watch(() => props.user, () => {
  if (props.disabled) {
    return
  }

  emit('update:model-value', null)
})

const items = ref<Jobsite[]>([])
const loading = ref(false)
const showJoin = ref(false)

const { $api, $error } = useNuxtApp()

const add = (jobsite: Jobsite) => items.value.push(jobsite)
const fetchJobsites = async () => {
  if ((!props.user && props.userRequired) || props.disabled) {
    return
  }

  loading.value = true
  try {
    items.value = await $api.v1.jobsites.list({ ...props.params, user: props.user })
    items.value.sort((a, b) => a.name!.localeCompare(b.name!))
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

watchEffect(fetchJobsites)

const itemName = (site: Jobsite) => {
  return `${site.project_number} - ${site.name} - ${site.address}, ${site.city}, ${site.province}, ${site.country}`
}
const joined = (jobsite: Jobsite) => {
  items.value.push(jobsite)
  emit('update:model-value', jobsite)
}
const remove = (jobsite: Jobsite) => {
  items.value = items.value.filter(item => item.id !== jobsite.id)
}

defineExpose({ add, remove })
</script>
