import * as msal from '@azure/msal-browser'

const config = {
  auth: {
    clientId: '92494b4d-f2d4-4b32-bc6a-8406c81b304d',
    redirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: number, message: string, containsPii: boolean) => {
        if (containsPii) {
          return
        }

        switch (level) {
          case msal.LogLevel.Error:
            console.error(message)
            return
          case msal.LogLevel.Info:
            console.info(message)
            return
          case msal.LogLevel.Verbose:
            console.debug(message)
            return
          case msal.LogLevel.Warning:
            console.warn(message)
            break
          default:
            break
        }
      }
    }
  }
}

export const msalObj = new msal.PublicClientApplication(config)

export default defineNuxtPlugin(async () => {
  await msalObj.initialize()

  return {
    provide: { msal: msalObj }
  }
})
