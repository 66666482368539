<template>
  <v-menu v-model="showDatePicker" v-bind="menuProps" @update:model-value="emitClosed">
    <template v-slot:activator="{ props }">
      <slot name="activator" :props="props">
        <qtm-text-field
          v-model="fullDateString"
          v-bind="{ ...$attrs, ...props }"
          :placeholder="placeholder"
          prepend-inner-icon="mdi-calendar"
          readonly
        />
      </slot>
    </template>
    <slot
      :date-string="dateString"
      :date-value="dateValue"
      :min-date="minDate"
    >
      <vdate-picker
        v-if="isRange"
        v-model.range="dateValue"
        color="interactive"
        :max-date="maxDate"
        :min-date="minDate"
        @dayclick="
          (_, event) => {
            event.target.blur();
          }
        "
      />
      <vdate-picker
        v-else
        v-model="dateValue"
        color="interactive"
        :max-date="maxDate"
        :min-date="minDate"
        @dayclick="
          (_, event) => {
            event.target.blur();
          }
        "
      />
    </slot>
    <slot name="append" />
  </v-menu>
</template>

<script>
import 'v-calendar/dist/style.css'
import { DatePicker } from 'v-calendar'
import { DateTime } from '@quotetome/materials-api'
import { dateFormat } from '~/models/filters'

export default {
  name: 'date-picker',
  components: { 'vdate-picker': DatePicker },
  props: {
    closeOnClick: {
      type: Boolean,
      default: false
    },
    cutoff: {
      type: Object,
      default: undefined
    },
    dateStringAppend: {
      type: String,
      default: ''
    },
    emitDate: {
      type: Boolean,
      default: false
    },
    formatString: {
      type: String,
      default: dateFormat
    },
    formatStringAppend: {
      type: String,
      default: ''
    },
    maxDate: {
      type: Object,
      default: undefined
    },
    menuProps: {
      type: Object,
      default: () => ({ closeOnContentClick: false, minWidth: 'auto' })
    },
    newHour: {
      type: [Number, String],
      // Default to noon to resist drifting days when viewing across timezones
      default: 12
    },
    newMinute: {
      type: [Number, String],
      default: 0
    },
    placeholder: {
      type: String,
      default: 'Select Date'
    },
    modelValue: {
      type: Object,
      default: undefined,
    },
    isRange: {
      type: Boolean,
      default: false
    },
  },
  emits: ['closed', 'update:model-value'],
  data() {
    return {
      pickerDate: undefined,
      previousValue: this.modelValue,
      showDatePicker: false
    }
  },
  computed: {
    dateValue: {
      get() {
        return this.modelValue?.toDate?.() || this.modelValue
      },

      set(newDate) {
        if (this.emitDate) {
          this.$emit('update:model-value', newDate)
        }
        else if (newDate) {
          let date = new DateTime(this.modelValue || undefined)
            .withYear(newDate.getFullYear())
            .withMonth(newDate.getMonth())
            .withDay(newDate.getDate())

          if (!this.previousValue) {
            date = date.withHour(this.newHour)
              .withMinute(this.newMinute)
              .withSecond(0)
              .withMillisecond(0)
          }

          this.$emit('update:model-value', date)
          this.previousValue = new DateTime(date)
        }
        else {
          this.$emit('update:model-value', null)
          this.previousValue = null
        }
        if (this.closeOnClick) {
          this.showDatePicker = false
        }
      }
    },
    dateString() {
      let date = this.modelValue

      if (date && !DateTime.isDateTime(date)) {
        date = new DateTime(date)
      }

      return date?.format(this.formatString) || ''
    },
    fullDateString: {
      get() {
        let date = this.modelValue

        if (date && !DateTime.isDateTime(date)) {
          date = new DateTime(date)
        }

        const dateString = date?.format(this.formatString + this.formatStringAppend) || ''

        return dateString + this.dateStringAppend
      },
      // Only set when clearing
      set() {
        this.$emit('update:model-value', null)
        this.previousValue = null
      }
    },
    minDate() {
      return this.cutoff?.toDate() || undefined
    },
  },
  mounted() {
    if (!this.modelValue && this.cutoff) {
      this.pickerDate = this.cutoff.format('YYYY-MM')
    }
  },
  methods: {
    close() {
      this.showDatePicker = false
    },
    emitClosed(value) {
      if (!value) {
        this.$emit('closed')
      }
    },
    setValue(value) {
      this.dateValue = value
    },
  }
}
</script>

<style lang="scss">
.weekday-1:not(.is-disabled):not(.in-next-month),
.weekday-7:not(.is-disabled):not(.in-next-month) {
  opacity: 0.6;
}

$nums: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900;

.vc-container {
  --rounded-lg: 0;

  @each $n in $nums {
    --interactive-#{$n}: rgb(var(--v-theme-interactive));
  }
}

.vc-container.vc-interactive {
  @each $n in $nums {
    --vc-accent-#{$n}: rgb(var(--v-theme-interactive));
  }
}
</style>
