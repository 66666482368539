<template>
  <div class="overflow-x-auto">
    <table class="purchase-order-items-table text-left qtm-body">
      <thead>
        <tr class="bg-background qtm-h4 text-secondary text-no-wrap">
          <td
            v-for="header in headers"
            :key="header.text"
            class="pl-3 py-2"
            :class="header.class"
            v-text="header.text"
          />
        </tr>
      </thead>
      <tbody>
        <purchase-order-item-row
          v-for="item in sortedItems"
          :key="item.id"
          :for-quote="forQuote"
          :from-bom="fromBom"
          :boms="boms"
          :item="item"
          :quotes="quotes"
          :with-duration="includeDuration"
          :with-received="withReceived"
        />

        <!-- Deprecated but needed for historic orders -->
        <tr v-if="deliveryCharge">
          <td />
          <td class="text-left">
            Delivery Charge
          </td>
          <td v-for="i in freightPadding" :key="i">
            -
          </td>
          <td class="text-no-wrap text-right" v-text="currency(deliveryCharge)" />
          <td class="text-no-wrap text-right" v-text="currency(deliveryCharge)" />
          <td v-text="freightCostCode" />
        </tr>
        <tr v-if="pickupCharge">
          <td />
          <td class="text-left">
            Pickup Charge
          </td>
          <td v-for="i in freightPadding" :key="i">
            -
          </td>
          <td class="text-no-wrap text-right" v-text="currency(pickupCharge)" />
          <td class="text-no-wrap text-right" v-text="currency(pickupCharge)" />
          <td v-text="freightCostCode" />
        </tr>

        <tr v-if="!fromBom" class="bg-background qtm-h4 text-secondary">
          <td class="py-4 text-right" :colspan="headers.length">
            <div>
              {{ totalLabel }} {{ currency(totalPrice) }}
            </div>
            <div v-if="tax" class="qtm-body">
              Tax {{ currency(tax) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { currency } from '~/models/filters'
import PurchaseOrderItemRow from '@/components/purchase-orders/purchase-order-item-row.vue'

export default {
  name: 'purchase-order-items-table',
  components: { PurchaseOrderItemRow, },
  props: {
    forQuote: {
      type: Boolean,
      default: false
    },
    fromBom: {
      type: Boolean,
      default: false
    },
    boms: {
      type: Array,
      default: () => []
    },
    deliveryCharge: {
      type: [Number, String],
      default: 0
    },
    freightCostCode: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    pickupCharge: {
      type: [Number, String],
      default: 0
    },
    quotes: {
      type: Array,
      default: () => []
    },
    totalLabel: {
      type: String,
      default: 'Total (excl. tax)'
    },
    totalPrice: {
      type: Number,
      default: 0
    },
    tax: {
      type: Number,
      default: 0
    },
    withReceived: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    headers() {
      if (this.forQuote) {
        const headers = [{ text: '#' }, { text: 'Description' }]

        if (this.includeDuration) {
          headers.push({ text: 'Duration' })
        }

        return headers.concat([
          { text: 'QTY' },
          { text: 'U/M' },
          { text: 'Unit Price', class: 'text-right' },
          { text: 'Subtotal', class: 'text-right' },
        ])
      }

      if (this.fromBom) {
        return [
          { text: '#' },
          { text: 'Description' },
          { text: 'QTY' },
          { text: 'Unit Price', class: 'text-right' },
          { text: 'Preferred Supplier' },
        ]
      }

      const headers = [{ text: '#' }, { text: 'Description' }]

      if (this.includeDuration) {
        headers.push({ text: 'Duration' })
      }

      headers.push({ text: 'Ordered' })

      if (this.withReceived) {
        headers.push({ text: 'Received' })
      }

      return headers.concat([
        { text: 'Unit Price', class: 'text-right' },
        { text: 'Subtotal', class: 'text-right' },
        { text: 'Cost Code' },
      ])
    },
    freightPadding() {
      return this.headers.length - (this.includeDuration ? 4 : 5)
    },
    includeDuration() {
      return this.items.some(item => item.rental_duration)
    },
    sortedItems() {
      const items = this.items

      items.sort((a, b) => a.item_number - b.item_number)
      return items
    },
  },
  methods: {
    currency,
  }
}
</script>

<style lang="scss">
$border: 1px solid rgb(var(--v-theme-light-grey));

.purchase-order-items-table {
  border-collapse: collapse;
  width: 100%;
}

.purchase-order-items-table td,
.purchase-order-items-table th {
  border: $border;
  padding: 16px 12px;
  vertical-align: top;
}
</style>
