<template>
  <v-menu content-class="qtm-border">
    <template v-slot:activator="{ props: menu, isActive }">
      <v-btn class="mb-n6 mr-n3 mt-2" :ripple="false" variant="plain" v-bind="menu">
        <span class="qtm-body text-dark-grey">Show: </span>
        <span class="qtm-body text-mid-grey text-capitalize">{{ sort.key }} First</span>
        <v-icon size="small">
          {{ isActive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </template>
    <v-list class="py-0">
      <v-list-item class="py-0 px-6 text-dark-grey" @click="sortNewest">
        Newest first
      </v-list-item>
      <v-list-item class="py-0 px-6 text-dark-grey" @click="sortOldest">
        Oldest first
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang='ts'>
interface Props {
  modelValue: string
}
const props = defineProps<Props>()

const emits = defineEmits(['update:model-value'])

const Sort = {
  Newest: { key: 'newest', icon: 'mdi-arrow-up-bold' },
  Oldest: { key: 'oldest', icon: 'mdi-arrow-down-bold' },
} as const

const sort = computed(() => Object.values(Sort).find((s) => s.key === props.modelValue))
const sortKey = localStorage.getItem('comments.sort')

const sortItems = (key: string) => {
  const sortValue = Object.values(Sort).find((s) => s.key === key)

  if (sortValue) {
    localStorage.setItem('comments.sort', sortValue.key)
    emits('update:model-value', sortValue.key)
  }
}

const sortNewest = () => sortItems(Sort.Newest.key)
const sortOldest = () => sortItems(Sort.Oldest.key)

onMounted(() => {
  if (sortKey) {
    sortItems(sortKey)
  }
})
</script>
