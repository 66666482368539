<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div>
        {{ itemType }} Items
      </div>
      <div>
        {{ itemsToMatch.length }} items
      </div>
    </div>
    <qtm-table fixed-columns="0" :headers="headers" :items="itemsWithNumber">
      <template v-slot:item.description="{ item }">
        <div v-text="item.description" />
        <div v-if="item.reference_identifier" class="text-mid-grey">
          Matched to:
          <div>{{ getMatch(item) }}</div>
        </div>
      </template>
      <template v-slot:item.price="{ item }">
        <div v-if="!item.rental_duration">
          {{ currency(item.unit_price) }}/{{ item.unit }}
        </div>
        <div v-else>
          <div v-if="item.day_rate">
            {{ currency(item.day_rate) }}/day
          </div>
          <div v-if="item.week_rate">
            {{ currency(item.week_rate) }}/week
          </div>
          <div v-if="item.month_rate">
            {{ currency(item.month_rate) }}/month
          </div>
        </div>
      </template>
      <template v-slot:item.match="{ item }">
        <div class="text-center">
          <qtm-btn
            class="mt-n1"
            color="interactive"
            :prepend-icon="item.reference_identifier ? 'mdi-pencil' :''"
            tertiary
            @click="matchItem = item"
          >
            {{ item.reference_identifier ? 'Edit' : 'Match' }}
          </qtm-btn>
        </div>
      </template>
    </qtm-table>
    <match-item-dialog
      :initial-item="matchItem"
      :items-to-match="itemsWithNumber"
      :model-value="matchItem != undefined"
      :match-item-type="matchItemType"
      :match-items="matchItems"
      :qty-header="qtyHeader"
      :single-match="singleMatch"
      @update:items-to-match="$emit('update:items-to-match', $event)"
      @update:model-value="matchItem = undefined"
    />
  </div>
</template>

<script setup lang="ts">
import MatchItemDialog from '@/components/items/match-item-dialog.vue'
import QtmTable from '@/components/qtm-table.vue'
import { currency } from '@/models/filters'

export interface Props {
  itemsToMatch: any[]
  itemType?: string
  matchItems: any[]
  matchItemType?: string
  qtyHeader?: string
  singleMatch?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  itemType: 'Invoice',
  matchItemType: 'PO',
  qtyHeader: 'Qty',
  singleMatch: false,
})
defineEmits(['update:items-to-match'])

const headers = [
  { title: '#', value: 'number', width: 50 },
  { title: 'Description', value: 'description' },
  { title: props.qtyHeader, value: 'quantity', width: 100 },
  { title: 'Unit Price', value: 'price', width: 170 },
  { title: `Match to ${props.matchItemType} Item`, value: 'match', width: 168 },
]

const itemsWithNumber = computed(() => props.itemsToMatch.map((item, i) => ({ ...item, number: i + 1 })))

const matchItem = ref<any>()

const getMatch = (item: any) => {
  return props.matchItems.find(i => i.internal_identifier === item.reference_identifier)?.description ?? ''
}
</script>
