<template>
  <attachments-row v-bind="$attrs" label="Invoice" :span-offset="props.invoices.length">
    <template v-slot:attachments>
      <tr v-for="invoice in sortedInvoices" :key="invoice.id">
        <td class="pa-4">
          <nuxt-link
            class="attachment-link text-decoration-none"
            :to="{ name: 'orders-id-invoices-invoice', params: { id: order.id, invoice: invoice.id } }"
          >
            Invoice #{{ invoice.invoice_number }} - {{ getTotalPrice(invoice) }}
          </nuxt-link>
        </td>
        <td class="py-4 text-no-wrap">
          {{ dateCreated(invoice) }}
        </td>
      </tr>
    </template>
  </attachments-row>
</template>

<script setup lang="ts">
import type { Invoice, Order, PurchaseOrder } from '@quotetome/materials-api'
import { dateYear, currency } from '~/models/filters'
import AttachmentsRow from '@/components/attachments/attachments-row.vue'

export interface Props {
  invoices?: Invoice[]
  order: Order
  po: PurchaseOrder
}

const props = withDefaults(defineProps<Props>(), {
  invoices: () => [],
})

const sortedInvoices = computed(() => {
  const invoiceCopy = props.invoices

  invoiceCopy.sort((a, b) => (b.date_created as number) - (a.date_created as number))

  return invoiceCopy
})

const dateCreated = (invoice: Invoice) => dateYear(invoice.date_created)
const getTotalPrice = (invoice: Invoice) => {
  if (invoice?.total_price) {
    return currency(Number(invoice.total_price) + Number(invoice.total_tax || 0))
  }

  return currency(Number(props.po.total_price) + Number(props.po.total_tax || 0))
}
</script>

<style lang="scss" scoped>
.attachment-link {
  font-weight: 500;
}
</style>
