<template>
  <qtm-content-block v-bind="$attrs">
    <project-authority :project="order.jobsite" />
    <invoice-title :invoice="invoice" :order="order" />

    <div class="text-dark-grey qtm-body">
      <invoice-information-table
        :contact="contact"
        :date-issued="invoice.date_issued"
        :date-due="invoice.date_due"
        :total-price="totalPrice"
        :vendor-name="vendorName"
      />

      <div class="heavy-border mt-8" />

      <cost-summary-table :items="items" readonly :total-price="totalPrice" />
    </div>
    <div class="text-dark-grey d-flex">
      <div>
        <attachment-preview-download
          v-for="a in invoice.attachments"
          :key="a.id"
          :attachment="a"
          class="mr-3"
          @click="$emit('preview', a)"
        />
      </div>
      <div v-if="comments" class="comments">
        <div class="font-weight-bold">
          Comments
        </div>
        <div v-text="comments" />
      </div>
    </div>
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Order } from '@quotetome/materials-api'
import AttachmentPreviewDownload from '@/components/attachments/attachment-preview-download.vue'
import CostSummaryTable from '@/components/orders/cost-summary-table.vue'
import InvoiceInformationTable from '@/components/orders/invoice-information-table.vue'
import InvoiceTitle from '@/components/invoices/invoice-title.vue'
import ProjectAuthority from '@/components/jobsites/project-authority.vue'

export interface Props {
  order: Order
}

const props = defineProps<Props>()
defineEmits(['preview'])

const invoice = computed(() => props.order.invoices[0])
const quote = computed(() => props.order.quotes[0])

const comments = computed(() => quote.value?.comments ?? invoice.value?.comments)
const contact = computed(() => quote.value?.salesperson ?? invoice.value?.contact)
const items = computed(() => quote.value?.skus ?? invoice.value?.line_items ?? [])
const totalPrice = computed(() => Number(quote.value?.total_price ?? invoice.value?.total_price))
const vendorName = computed(() => quote.value?.vendor?.name ?? invoice.value?.vendor?.name)
</script>

<style scoped>
.comments {
  min-width: 75%;
}

.heavy-border {
  border-bottom: 4px solid rgb(var(--v-theme-secondary));
}
</style>
