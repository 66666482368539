import { useRoute, useRouter } from 'vue-router'

const changes = new Map<string, any>()

export default function useQueryParamObject(
  param: string,
  obj: Ref<any>,
  { value = 'id' } = {},
): Ref<string | undefined> {
  const route = useRoute()
  const router = useRouter()

  const queryParam = ref(route.query?.[param] as string ?? undefined)

  watch(obj, () => {
    queryParam.value = toValue(obj)?.[value] ?? toValue(obj) ?? undefined
    changes.set(param, queryParam.value)

    nextTick(() => {
      if (changes.size === 0) {
        return
      }

      const query = { ...route.query }

      for (const [key, change] of changes.entries()) {
        query[key] = change
      }

      changes.clear()
      router.replace({ query })
    })
  }, { flush: 'post' })

  return queryParam
}
