<template>
  <qtm-content-block>
    <div class="d-flex flex-wrap pb-4 justify-space-between">
      <div class="qtm-h1 text-secondary">
        Invoice {{ invoice.invoice_number }}
        <invoice-status v-if="!order.date_cancelled" class="ml-2" :invoice="invoice" />
      </div>
      <div>
        <slot name="actions" />
      </div>
    </div>
    <details-container :details="details">
      <template v-slot:project>
        <span v-if="order.jobsite">
          {{ order.jobsite.name }}
          <qtm-icon-btn
            color="mid-dark-grey"
            class="mt-n1"
            density="compact"
            icon="mdi-open-in-new"
            size="small"
            title="View project page"
            :to="{ name: 'projects-id', params: { id: order.jobsite.id } }"
            target="_blank"
          />
        </span>
      </template>
      <template v-slot:po>
        <span v-if="po?.po_number">
          {{ po.po_number }}
          <qtm-icon-btn
            color="mid-dark-grey"
            class="mt-n1"
            density="compact"
            exact
            icon="mdi-open-in-new"
            size="small"
            title="View PO"
            :to="{ name: 'orders-id', params: { id: order.id } }"
            target="_blank"
          />
        </span>
      </template>
    </details-container>
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Invoice, Order } from '@quotetome/materials-api'
import DetailsContainer from '@/components/details-container.vue'
import InvoiceStatus from '@/components/invoices/invoice-status.vue'

export interface Props {
  invoice: Invoice
  order: Order
}

const props = defineProps<Props>()

const details = computed(() => [
  { label: 'Project', value: 'project' },
  { label: 'Ordered by', value: 'owner', text: `${props.order.owner!.first_name} ${props.order.owner!.last_name}` },
  { label: 'PO Number', value: 'po', text: po.value?.po_number ?? 'No PO Issued' },
])
const po = computed(() => props.order.pos[0])
</script>

<style lang="scss" scoped>
.invoice-number {
  font-family: "Roboto Condensed", Roboto, sans-serif;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.invoice-title {
  font-family: Roboto, sans-serif;
}
</style>
