<template>
  <div>
    <qtm-content-block v-if="order.orderType.fromBom" class="mb-4 pt-2">
      <div class="qtm-label">
        <v-icon class="mr-1">
          mdi-information-outline
        </v-icon>
        This is a <span class="text-primary">BoM RFQ</span>, please follow the steps:
      </div>
      <ol class="qtm-body ml-14 mt-2">
        <li>
          Enter quote
        </li>
        <li>
          Match items from the quote to the RFQ items. The system will try to match automatically but it’s not ideal.
        </li>
        <li>
          If supplier added items to the quote that are not in the RFQ, leave these items unmatched.
        </li>
      </ol>
    </qtm-content-block>

    <v-expansion-panels v-model="openPanels" multiple>
      <v-expansion-panel class="qtm-border" elevation="0">
        <v-expansion-panel-title class="font-weight-bold">
          {{ documentName }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <invoice-review v-if="showInvoiceReview" :order="order" @preview="$emit('preview', $event)" />
          <quotes-review v-else :order="order" />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="qtm-border" elevation="0">
        <v-expansion-panel-title class="font-weight-bold">
          Invited Vendors
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <enter-vendor-quotes-table :order="order" />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="qtm-border" elvation="0">
        <v-expansion-panel-title class="font-weight-bold">
          Order
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <order-detail :order="order" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { OrderState } from '@quotetome/materials-api'
import EnterVendorQuotesTable from '@/components/vendors/enter-vendor-quotes-table.vue'
import InvoiceReview from '@/components/quotes/invoice-review.vue'
import QuotesReview from '@/components/quotes/quotes-review.vue'
import OrderDetail from '@/components/orders/order-detail.vue'

export default {
  name: 'enter-quotes',
  components: { EnterVendorQuotesTable, InvoiceReview, QuotesReview, OrderDetail },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['preview'],
  data() {
    return {
      openPanels: [0],
    }
  },
  computed: {
    documentName() {
      return this.order.is_invoice_approval ? 'Invoice' : 'Quotes'
    },
    showInvoiceReview() {
      return this.order.is_invoice_approval && this.order.invoices.length
    },
  },
  mounted() {
    if (this.order.state.id === OrderState.WAITING_FOR_QUOTES.id) {
      this.openPanels.push(1)
    }
    else {
      this.openPanels.push(0)
    }
  },
}
</script>
