<template>
  <div v-intersect="onIntersect">
    <div class="d-flex justify-space-between text-dark-grey">
      <div class="qtm-label">
        {{ userName }}
        <qtm-chip v-if="message.type === 'inbound'" class="ml-2" color="secondary-lighten-2" text-color="secondary">
          Supplier
        </qtm-chip>
      </div>
      <div class="qtm-overline text-mid-dark-grey" v-text="dateTime(message.date_created)" />
    </div>
    <div class="show-newlines" v-text="message.text" />
    <div v-if="message.attachments.length" class="d-flex mt-3">
      <attachment-preview-download
        v-for="attachment in message.attachments"
        :key="attachment.id"
        :attachment="attachment"
        class="mr-3"
        download-only
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Attachment } from '@quotetome/materials-api'
import AttachmentPreviewDownload from '@/components/attachments/attachment-preview-download.vue'
import { DateTime } from '@quotetome/materials-api'
import { dateTime } from '@/models/filters'

export interface Props {
  markAsRead?: boolean
  message: {
    id: number,
    attachments: Attachment[],
    created_by: { first_name: string, last_name: string },
    date_created: number,
    date_read: number | null,
    text: string,
    type: 'inbound' | 'outbound',
  }
}

const props = defineProps<Props>()

const userName = computed(() => `${props.message.created_by.first_name} ${props.message.created_by.last_name}`)
const { $api, $error } = useNuxtApp()
const onIntersect = async (isIntersecting: boolean) => {
  if (isIntersecting && !props.message.date_read && props.markAsRead) {
    try {
      await $api.v1.notifications.emailChat.markAsRead(props.message.id)
      props.message.date_read = new DateTime().unix()
    }
    catch (error) {
      $error.report(error)
    }
  }
}
</script>
