<template>
  <qtm-content-block v-bind="$attrs" title="Quotes">
    <qtm-content-block v-if="noQuotesReturned" class="mb-6 bg-background">
      No quotes have been returned. As a next step, you may wish to
      <nuxt-link :to="{ name: 'orders-id-invite-suppliers', params: { id: order.id } }">
        invite more suppliers
      </nuxt-link>
      to this request.
    </qtm-content-block>
    <quotes-grid
      :md="gridMd"
      :order="order"
      :quotes="sortedQuotes"
      :show-actions="showActions"
      @select="activeQuote = $event"
      @update:order="$emit('update:order', $event)"
    />
    <quote-detail-dialog
      v-if="activeQuote"
      v-model:quote="activeQuote"
      model-value
      :order="order"
      :quotes="sortedQuotes"
      :show-actions="showActions"
      @update:model-value="activeQuote = undefined"
      @update:order="$emit('update:order', $event)"
    />
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Order, Quote } from '@quotetome/materials-api'
import QuoteDetailDialog from '@/components/quotes/quote-detail-dialog.vue'
import QuotesGrid from '@/components/quotes/quotes-grid.vue'
import useQueryParamObject from '@/composables/query-param-object'

export interface Props {
  gridMd?: number
  order: Order
  showActions?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  gridMd: 4,
})

defineEmits(['update:order'])

const activeQuote = ref<Quote>()
useQueryParamObject('quote', activeQuote)

const noQuotesReturned = computed(() => !props.order.readyQuotes.length && props.order.waitingForPO)
const sortedQuotes = computed(() => {
  const otherQuotes = props.order.quotes.filter(quote => quote.response_received !== 'price given')

  return props.order.readyQuotes.concat(otherQuotes)
})

const route = useRoute()

onMounted(() => {
  if (route.query.quote) {
    activeQuote.value = props.order.quotes.find(quote => quote.id === Number(route.query.quote))
  }
})
</script>
