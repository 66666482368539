<template>
  <span v-intersect="onIntersect" class="position-relative">
    <div v-if="show" class="d-flex justify-space-between pa-4 text-light-grey tutorial-tooltip">
      <div>
        <div class="mb-2 qtm-h4 text-white" v-text="title" />
        <div class="qtm-body mb-4">
          <slot name="text">
            {{ text }}
          </slot>
        </div>
        <div>
          <qtm-btn @click="show = false">
            Got It
          </qtm-btn>
        </div>
      </div>
      <qtm-icon-btn
        class="mt-n1 mr-n1"
        color="white"
        density="compact"
        icon="mdi-close"
        @click="show = false"
      />
    </div>
    <slot />
  </span>
</template>

<script setup lang="ts">
export interface Props {
  text?: string
  title: string
  value: string
}

const props = defineProps<Props>()

const authStore = useAuthStore()
const user = computed(() => authStore.user)
const shouldShow = computed(() => !user.value?.[props.value as keyof typeof user.value])

const show = ref(false)

const onIntersect = (isIntersecting: boolean) => {
  if (isIntersecting && !show.value && shouldShow.value) {
    show.value = true
    acknowledge()
  }
}

const { $api, $error } = useNuxtApp()

const acknowledge = async () => {
  try {
    const updatedUser = await $api.v1.users.patch(user.value!.id!, { [props.value]: true })

    authStore.updateUser(updatedUser)
  }
  catch (error) {
    $error.report(error)
  }
}
</script>

<style lang="scss" scoped>
$color: rgb(var(--v-theme-mid-dark-grey));

.tutorial-tooltip {
  background: $color;
  position: absolute;
  bottom: calc(100% + 10px);
  width: 375px;
  z-index: 1000;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 40px;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: $color transparent transparent transparent;
  }
}
</style>
